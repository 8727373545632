.lesson-guides {
  .dx-texteditor-input {
    color: black;
  }

  &__list {
    &-container {
    }

    &-header {
      padding: 10px 0px;
      padding-left: 20px;
      background-color: #252d65;
      span {
        font-weight: bold;
        color: white;
      }
    }
  }

  &__list-item {
    &-header {
      cursor: pointer;
      padding: 10px 0;
      padding-left: 20px;
      border-top: 1px solid #d6d7e2;

      .col {
        display: flex;
        align-items: center;
      }
    }

    &-container {
    }
  }
}
