.dashboard {
  @media screen and (max-width: 780px) {
    .menuItem {
      width: 150px !important;
      height: 200px !important;
      margin: auto !important;
      box-shadow: 3px 3px 3px 8px lightblue !important;
    }
  }

  @media screen and (min-width: 781px) {
    .menuItem {
      width: 220px !important;
      height: 250px !important;
      margin: auto !important;
      box-shadow: 3px 3px 3px 8px lightblue !important;
    }
  }

  .col {
    padding: 5%;
  }

  .cardContainer {
    margin: 40px;
  }

  @media screen and (max-width: 780px) {
    .image {
      width: 90px;
      height: 90px;
      cursor: pointer;
    }
  }

  @media screen and (min-width: 781px) {
    .image {
      width: 130px;
      height: 150px;
      cursor: pointer;
      margin: auto;
    }
  }

  .bodyContent {
    margin: 10px;
    cursor: pointer;
  }
}
