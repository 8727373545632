.prompt-mgmt {
  .prompt-name {
    display: flex;
    /* margin-left: auto; */
    margin-right: auto;
    width: 100%;
  }

  .prompt-mgmt-buttons-col {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
  }

  .dx-field-label {
    width: 9em;
  }

  .dx-field-value {
    width: 80% !important; // in case browser doesn't have calc()
    width: calc(100% - 9em) !important; // necessary because
    float: left;
  }

  #prompt-stimulus {
    min-height: 116px;
  }

  label.bigFieldLabel {
    @extend h4;
    @extend .card-title;
  }

  amplify-s3-image.dropzone-promptImage.hydrated,
  amplify-s3-image.prompt-img.hydrated {
    --width: auto;
    --height: 400px;
  }

  .prompt-mgmt-buttons-col {
    .dx-button-content {
      line-height: 0;
    }
  }
}
