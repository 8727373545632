.longitudinal {
  position: relative;

  .print-button {
    position: absolute;
    right: 0;

    .dx-button {
      margin-right: 8px;
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 200;
    color: #232323;
    display: none;
    text-align: center;
    margin-bottom: 40px;
  }

  .ContainerIST {
    .label {
      width: 90%;
      margin: auto;
      padding: 5px;
      text-align: center;
    }
    .header {
      width: 90%;
      margin: left;
      text-align: left;
      font-size: 15pt;
    }

    .numberlabels {
      width: 100%;
      margin: auto;
      padding: 5px;
      text-align: left;
    }

    .redValueLoss {
      background-color: red;
      color: white;
      font-weight: bold;
    }
    .greenValueLoss {
      background-color: green;
      color: white;
      font-weight: bold;
    }
  }

  .longitudinalChart {
    margin: auto;
    height: 100%;
  }

  @media print {
    margin: 180px 0;
    padding: 60px;

    .print-button {
      display: none;
    }

    h1 {
      display: block;
    }
  }

  &.landscape {
    transform: rotate(90deg);
  }
}
