$breakpoints: (
  phone: 20em,
  tablet: 48em,
  desktop: 64em,
);

.dx-button-has-text.dx-button-content {
  @include media(">=tablet") {
    padding: 8px;
  }
}

// .dx-datagrid .dx-column-lines > td {
//   border-right: none !important;
//   border-left: none !important;
// }

.actionButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 18px;

  .dx-button-content {
    line-height: 0;
  }
}

.dx-button-mode-contained.dx-button-default {
  margin-right: 20px;
}

.classRoom {
  .p-2 {
    span {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}

.gradeLineItem {
  .editLink {
    font-size: 1rem;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
    margin-left: 20px;
  }
}

.viewEssay,
.print {
  .essayBody {
    min-width: 70%;
  }

  .essayPrompt {
    width: 30%;
    padding: 1rem;
  }

  .dx-datagrid-content .dx-datagrid-table .dx-row>td {
    white-space: normal;
  }

  .dx-datagrid .dx-row>td {
    padding: 1px;
  }

  .essayPrintContainer {
    @media screen {
      display: none;
    }

    @media print {
      display: unset;
    }
  }

  .studentEssayViewTextArea {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
    min-width: 0 !important;
    margin-bottom: 1.5rem !important;
    word-wrap: break-word !important;
    background-clip: border-box !important;
    border-radius: 0.25rem !important;
    background-color: #fff !important;
    margin-block-start: 1em !important;
    margin-block-end: 1em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    white-space: pre-line !important;
    border: 0 none #fff !important;
    outline: none !important;
    resize: none !important;
    cursor: context-menu !important;
  }

  .pdfHandwritingViewer {
    width: 80%;
    height: 98%;
  }

  .imageHandwritingViewer {
    width: 80% !important;
    height: 80% !important;
    margin: auto;
  }

  .cardContainer{
    height: 140%
  }
}

.classRoom {
  .dx-datagrid .dx-row>td {
    padding: 1px;
  }
}

.content {
  display: flex;
  align-items: center;

  .icon-format {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.word-count-style {
  font-weight: bold;
}

.page-break {
  @media screen {
    display: none;
  }

  @media print {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

.rubricText {
  height: 7rem;
  font-size: 0.6rem;
  padding: 4px;
}

.rubricScoreText {
  height: 7rem;
  padding: 4px;
  font-size: 4rem;
  text-align: center;
}

.rubricText-null {
  background-color: #ddd;
}

.explain-title {
  margin-top: 10px;
}
