.student-license {
  h5 {
    padding: 30px 20px 0px 20px;
  }

  &__status {
    display: flex;
    margin-bottom: 5px;

    .label {
      font-size: 15px;
      width: 150px;
      font-weight: bold;
      padding: 10px;
      background-color: lightblue;
      text-align: center;
      border: 1px solid lightslategray;
      border-right: 0;
    }

    .summary {
      width: 100%;
      display: flex;
      padding: 10px 150px;
      justify-content: space-between;
      border: 1px solid lightslategray;

      span {
        display: block;
      }

      &__detail {
        display: flex;

        span {
          &:nth-child(1) {
            margin-right: 8px;
          }

          &:nth-child(2) {
            font-weight: bold;
          }
        }
      }
    }
  }

  &__year-selection {
    margin-right: 10px;

    .dx-dropdowneditor {
      width: 100%;
    }
  }

  &__school-selection {
    .dx-dropdowneditor {
      width: 100% !important;
    }

    .school-select {
      margin-bottom: 16px;
    }
  }

  &__update {
    .selected-rows {
      display: flex;
      align-items: center;

      span {
        &:nth-child(1) {
          margin-right: 8px;
          &::before {
            content: "<";
          }
          &::after {
            content: ">";
          }
        }

        &:nth-child(2) {
          margin-right: 10px;
        }
      }
    }
  }
}
