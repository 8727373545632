// Here you can add other styles

// you can apply and remove the "spin" class to make an item spin (e.g. a refresh icon)
.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes quarter-spin-clockwise-reveal {
  0% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

@keyframes quarter-spin-anticlockwise-reveal {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
  }
}

// end spin

// animation that gradually increases height e.g. upon insertion into a DOM
@keyframes elastik-reveal-height {
  from {
    line-height: 0px;
    height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  to {
    line-height: 1rem;
    height: 100%;
  }
}

// animation that gradually reduces height e.g. upon removal from a DOM
@keyframes elastik-reduce-height {
  to {
    line-height: 0px;
    height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.bg-gray {
  background-color: #e6e6e6;
}

.bg-white {
  background-color: white;
}

.c-main {
  padding-top: 0.2rem;
}

.container-fluid {
  padding-left: 15px;
  padding-right: 15px;

  @media screen and (min-width: 768px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.login-container {
  height: 100vh;
  margin: 0px;
  max-width: none;
  display: flex;
}

.login-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-page-row {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
}

.login-img {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}

.login-logo {
  width: 300px;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    position: relative;
    width: 200px;
    height: 70px;
    margin-top: 48px;
  }
}

.login-card {
  margin: auto;
  padding: 1rem;
  border: 0px;
  max-width: 400px;

  .password-requirements {
    margin-top: 15px;
    margin-left: calc(-1rem - 1px);
    margin-right: calc(-1rem - 1px);
    padding: 1rem;
    background-color: white;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1024px) {
    position: relative;
    width: 90%;
  }
}

.login-card-container {
  display: flex;
  justify-content: center;
}

.login-card-uk {
  margin: auto;
  padding: 1rem;
  border: 0px;
  background-color: #e6e6e6;

}

.brand-text {
  color: #252d65;
}

.brand-text-uk {
  color: #0000ff !important;

  span>span.dx-field-item-label-text {
    color: #0000ff !important;
  }

  span {
    color: #0000ff !important;
  }
}

.brand-singIn-button-uk {
  div {
    background-color: #99ffff !important;
    border-radius: 20px;
  }

  div>div {
    background-color: #99ffff !important;
    border-radius: 20px;
  }

  span {
    color: #0000ff !important;
  }
}

.card {
  margin-bottom: 0.5rem;
}

.card-half-bottom-margin {
  margin-bottom: 0.25rem;
}

.card-no-bottom-margin {
  margin-bottom: 0;
}

.text-align-center {
  text-align: center;
  align-self: center;
}

.dx-toast-content {
  min-width: 300px;
  max-width: 400px;
}

.dx-datagrid {
  padding: 5px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-master-detail-cell {
  padding: 5px;
}

.dx-button.dx-button-default {
  border-radius: 20px;
}

.dx-button.dx-button-normal {
  border-radius: 20px;
}

.dx-button.dx-button-mode-contained {
  border-radius: 20px;
}

.dx-button.dx-button-mode-contained.dx-state-hover[aria-label="Save"] {
  background-color: #74c07d;
  border-color: #74c07d;
}

.dx-button.dx-button-mode-contained.dx-state-focused[aria-label="Save"] {
  background-color: #74c07d;
  border-color: #74c07d;
}

.dx-button.dx-button-mode-contained.dx-state-hover[aria-label="Yes"] {
  background-color: #74c07d;
  border-color: #74c07d;
}

.dx-button.dx-button-mode-contained.dx-state-focused[aria-label="Yes"] {
  background-color: #74c07d;
  border-color: #74c07d;
}

.dx-button.dx-datagrid-form-buttons-container {
  border-radius: 20px;
}

.dx-button[aria-label="Save"] {
  background-color: #5fbf7f;
  border-color: #5fbf7f;
  color: white;
}

.dx-button[aria-label="Cancel"] {
  background-color: rgb(245, 237, 237);
  border-color: rgb(245, 237, 237);
}

.dx-button[aria-label="Yes"] {
  background-color: #5fbf7f;
  border-color: #5fbf7f;
  color: white;
}

.dx-button[aria-label="No"] {
  background-color: rgb(223, 223, 223);
}

.lesson-card {
  position: relative;

  .card-img-overlay {
    z-index: 1;
  }

  a {
    z-index: 2;
  }

  &__image {
    z-index: 2;
    opacity: 0.9;
    border-radius: 10px;
  }
}

.no-link:hover {
  text-decoration-line: none;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  border-radius: 10px;
  padding: 10px 10px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid lightblue;

  &:hover {
    transition: all 0.2s ease-out;
    box-shadow: 0px 4px 6px 8px rgba(38, 38, 38, 0.2);
    top: -4px;
    border: 1px solid #cccccc;
    background-color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    left: 2px;
    background: #ee4491;
    height: 20px;
    width: 40px;
    border-radius: 25px;
    transform: scale(1.5);
    transform-origin: 50% 50%;
    transition: transform 0.15s ease-out;
  }

  &:hover:before {
    transform: scale(3.15);
  }
}

.primary:before {
  background: #252d65;
}

.secondary:before {
  background: #03bfd7;
}

.danger:before {
  background: #ee4491;
}

.success:before {
  background: #5fbf7f;
}

.purple:before {
  background: #674ea7;
}

.card__header {
  position: relative;
  top: 180px;
  border-radius: 5px;
  padding-left: 10px;
  background-color: #03bfd7;
  height: 70px;
  opacity: 0.9;

  @media screen and (max-width: 1100px) {
    height: 60px;
  }
}

.card-lesson {
  position: absolute;
  bottom: 0;
  left: 0;
  top: auto;
  height: auto;
  padding: 5px 10px;
  background-color: #03bfd7;
  width: 100%;

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    transition: opacity 50ms ease-in-out;

    &:hover {
      opacity: 0.5;
    }

    .card__header-title {
      text-decoration: none;
    }
  }
}

.card__header-title {
  margin: 0 0 0.25em;
  padding-top: 3px;
  color: #252d65;
  font-weight: bolder;
  font-style: italic;

  @media screen and (max-width: 1300px) {
    font-size: 0.85rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.65rem;
  }

  text-decoration: underline;
}

.card__header-meta {
  margin: 0;
  color: #252d65;
  font-weight: 500;

  @media screen and (max-width: 1300px) {
    font-size: 0.75rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.6rem;
  }
}

.lesson-card {
  &__image-container {
    display: block;
    position: relative;
  }

  &__image-frame {
    width: 100%;
    max-height: 300px;
    border: 0;
  }

  &__error-message {
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
    text-align: left;
    padding: 0 20px;
    line-height: 27px;
    color: #252d65;
  }

  &__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 268px;
  }
}

.quizdetail-card-without-height {
  &.is-selected {
    border: 1px solid #ee4491;
  }
}

.quizdetail-card {
  height: 400px;

  &.is-selected {
    border: 1px solid #ee4491;
  }
}

.quizdetail-card-without-height ul {
  margin-top: 10px;
  vertical-align: bottom;
  list-style: none;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    font-size: 0.75rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.6rem;
  }
}

.quizdetail-card ul {
  margin-top: 10px;
  vertical-align: bottom;
  list-style: none;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    font-size: 0.75rem;
  }

  @media screen and (max-width: 1100px) {
    font-size: 0.6rem;
  }
}

.quizdetail-li:before {
  content: "▢";
  margin-right: 5px;
}

.quizdetail-ul {
  margin: 0 20px 0 0;
  float: left;

  @media screen and (max-width: 1200px) {
    padding: 10px;
  }
}

.lesson-button {
  position: relative;
  top: 0px;
  color: #252d65;
  font-weight: bold;
  background-color: #03bfd7;
  width: 100px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 15px;
  text-align: center;
  text-decoration: underline;
}

.question-number {
  font-size: 16pt;
  text-align: left;
  margin-bottom: 10px;
  padding: 5px;
  padding-left: 10px;
  background-color: #03bfd7;
  border: 1px;
  border-radius: 25px;
  width: 20%;
  border-color: #03bfd7;
  color: white;
}

.accode-printing {
  font-size: 12pt;
  text-align: left;
  margin-bottom: 10px;
  padding: 5px;
  padding-right: 10px;
  background-color: #ee4491;
  border: 1px;
  border-radius: 25px;
  width: 15%;
  border-color: #ee4491;
  color: white;
}

.printed-lesson-button {
  position: relative;
  top: 0px;
  color: #252d65;
  font-weight: bold;
  background-color: #03bfd7;
  width: 100px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 15px;
  text-align: center;
  text-decoration: underline;
}

.premium-mark {
  position: relative;
  top: 5px;
  color: white;
  font-weight: bold;
  width: 100px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 15px;
  text-align: left;
  font-size: 10pt;
  text-align: center;
  background: #5fbf7f;
  height: 30px;
  margin: 5px;
}

.lesson-pageno {
  position: absolute;
  bottom: 10px;
  color: #252d65;
  font-weight: bold;
  background-color: #03bfd7;
  width: 100px;
  padding: 5px;
  padding-left: 10px;
  border-radius: 15px;
  text-align: center;
}

.lesson-frame {
  position: relative;
  top: 5px;
  left: 10px;
  width: 95%;
  height: 90%;
  z-index: -1;
  border: 0px;
}

.card-body {
  padding: 1px;
}

.col-xl-3 {
  @media screen and (min-width: 1200px) {
    flex: 0 0 33.3%;
    max-width: 33%;
  }

  @media screen and (min-width: 1600px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.signin-background {
  background-color: #ee4491;
}

.signin-background-uk {
  background-color: #e6e6e6;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 1;
}

.full-size {
  height: 100%;
  width: 100%;
}

#gridContainer {
  min-height: 86vh;
}

// did this change to have uk and au styles for the side bar
.c-sidebar {
  background: #252d65;

  >button {
    background: #252d65 !important;
  }

  >ul>li:hover,
  li:active {
    background: #252d65 !important;
  }

  >ul>li>a:hover {
    background: #252d65 !important;
  }

  .c-sidebar-nav-link.c-active {
    color: #fff;
    background: #ee4491;
    transition: background 0.4s;
  }

  .c-sidebar-brand {
    flex: 0 0 67px;
  }
}

.c-sidebar-uk {
  background: #0000ff;

  >button {
    background: #0000ff !important;
  }

  >ul>li:hover,
  li:active {
    background: #0000ff !important;
  }

  >ul>li>a {
    color: white;
    font-weight: 500 !important;
  }

  >ul>li>a:hover {
    background: #0000ff !important;
  }

  >ul>li.c-sidebar-nav-dropdown.c-show>ul>li>a:hover {
    background: #0000ff !important;
  }

  .c-sidebar-nav-link.c-active {
    background: #aaffff !important;
    color: #0000ff !important;
    font-weight: 500 !important;
  }

  .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-active {
    background: #aaffff !important;
    color: #0000ff !important;
    font-weight: 500 !important;
  }

  .c-sidebar-nav-dropdown.c-show {
    background: #0000ff !important;
  }

  .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
  .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    color: #0000ff;
  }
}

// .c-sidebar .c-sidebar-nav-link {
//   color: white;
// }

// .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-active {
//   color: #fff;
//   background: #ee4491;
//   transition: background 0.4s;
// }

// .c-sidebar .c-sidebar-nav-link.c-active {
//   color: #fff;
//   background: #ee4491;
//   transition: background 0.4s;
// }

.c-sidebar .c-sidebar-minimizer {
  background: #252d65;
}

img.card-img.quiz-card-img {
  object-fit: scale-down;
}

img.quiz-card-img {
  max-width: 350px;
  max-height: 125px;
  --width: 350px;
  --height: 125px;
  width: inherit;
  object-fit: scale-down;
}

img.quiz-card-answer-img {
  width: -webkit-fill-available;
}

amplify-s3-image.quiz-card-img {
  --height: 100px;
  --width: 400px;

  @media screen and (max-width: 1600px) {
    --height: 85px;
    --width: 340px;
  }

  @media screen and (max-width: 1400px) {
    --height: 80px;
    --width: 320px;
  }

  @media screen and (max-width: 1300px) {
    --height: 70px;
    --width: 250px;
  }

  @media screen and (max-width: 1100px) {
    --height: 60px;
    --width: 220px;
  }

  @media screen and (max-width: 1000px) {
    --height: 50px;
    --width: 200px;
  }
}

amplify-s3-image.review-card-img {
  --height: 148px;
  --width: 339px;

  @media screen and (max-width: 1600px) {
    --height: 100px;
    --width: 340px;
  }

  @media screen and (max-width: 1400px) {
    --height: 148px;
    --width: 339px;
  }

  @media screen and (max-width: 1300px) {
    --height: 90px;
    --width: 250px;
  }

  @media screen and (max-width: 1100px) {
    --height: 80px;
    --width: 220px;
  }

  @media screen and (max-width: 1000px) {
    --height: 70px;
    --width: 200px;
  }
}

amplify-s3-image.review-card-side-img {
  --height: 140px;
  --width: 140px;
}

amplify-s3-image.quiz-card-side-img {
  --height: 140px;
  --width: 140px;
}

amplify-s3-image.quiz-card-answer-img {
  --height: 75px;
  --width: 75px;

  @media screen and (max-width: 1100px) {
    --height: 50px;
    --width: 50px;
  }

  @media screen and (max-width: 900px) {
    --height: 40px;
    --width: 40px;
  }
}

amplify-s3-image {
  --height: 200px;
  --width: 200px;
}

amplify-s3-image-normal {
  --height: 200px;
  --width: auto;
}

.logo-image {
  --height: 40px;
  --width: 40px;
}

.school-logo {
  max-height: 50px;
  max-width: 100px;
}

.header-notification {
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.lit-iframe {
  min-height: 800px;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.m-10 {
  margin: 2rem;
}

img.student-profile-img {
  max-width: 90%;
  max-height: 75%;
  height: 300px;
  width: 300px;
  --height: 300px;
  --width: 300px;

  @media screen and (max-width: 1300px) {
    max-height: 60%;
  }

  @media screen and (min-width: 1600px) {
    max-height: 95%;
  }
}

/* writing prompt image */
img.prompt-img {
  max-width: 80%;
  max-height: 500px;
}

img.school-logo-img {
  height: 75px;
  width: 75px;
  --height: 75px;
  --width: 75px;
}

img.center-aligned-image {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.dropzone-image {
  height: 170px;
  width: 170px;
  --height: 170px;
  --width: 170px;
}

.dropzone-cellimage {
  height: 50px;
  width: 50px;
  --height: 50px;
  --width: 50px;
}

.dropzone-profileimage {
  max-width: 90%;
  height: 300px;
  width: 300px;
  --height: 300px;
  --width: 300px;
}

.dropzone-schoollogo {
  height: 75px;
  width: 75px;
  --height: 75px;
  --width: 75px;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#dropzone-external {
  width: 340px;
  height: 175px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-profileImage {
  max-width: 90%;
  width: 360px;
  height: 360px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-promptImage {
  max-width: 100%;
  min-width: 360px;
  min-height: 80px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;

  &.dropzone-active {
    background-color: rgba(183, 183, 183, 0.3);
    border-color: blue !important;
  }
}

#dropzone-cell {
  // width: 50px;
  height: 75px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-schoollogo {
  // width: 50px;
  height: 150px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

#dropzone-large {
  width: 100%;
  height: 200px;
  background-color: rgba(183, 183, 183, 0.1);
  border-width: 2px;
  border-style: dashed;
  padding: 10px;
}

.dx-theme-border-color {
  border-color: #ddd !important;
}

.dx-checkbox-icon {
  border: 1px solid rgba(37, 45, 101, 0.5);
  border-radius: 10px;
}

.quiz-checkbox {
  border: 2px solid rgba(37, 45, 101, 0.4);
  border-radius: 12px;
  opacity: 0.5;
  height: 14px;
  width: 14px;
}

.quiz-card-question-id {
  font-size: x-small;
  margin: 2em auto 0 auto;
  color: darkgray;
}

.quiz-result-image img {
  max-width: 100%;
}

.wrap-text {
  word-wrap: normal;
  width: 400px;
}

.align-end {
  text-align: flex-end !important;
}

.progress-map-item {
  font-size: 13px;
  padding-left: 5px;
  padding-right: 5px;
}

.progress-map-item-header {
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
}

.progress-map-item-bolder {
  font-size: 14px;
  margin-left: 10px;
  font-weight: bolder;
  padding: 0;
}

.lesson-edit {
  height: 800px;
  width: -webkit-fill-available;
  margin-left: -10px;
  margin-right: -10px;
  position: relative;
  z-index: 1;
}

.with-cursor {
  cursor: pointer;
}

#lesson-guide-name-popup {
  position: relative;
  height: 230px;

  .back-button,
  .add-button {
    position: absolute;
    bottom: 0;
  }

  .add-button {
    right: 0;
  }
}

// TODO: uncomment this if you want to disable the scrollbar
// .main-scoregrid {
//   .dx-scrollable-wrapper {
//     .dx-scrollable-container {
//       -ms-overflow-style: none !important; /* IE and Edge */
//       scrollbar-width: none !important; /* Firefox */

//       &::-webkit-scrollbar {
//         display: none;
//       }
//     }
//   }
// }
.userInfo,
.userInfo a {
  margin: 0 0 0 0;
  color: lightgray;
  text-align: right;

  span.gitSHA {
    font-size: x-small;
    margin-left: 0.5em;
  }
}

.breadcrumb {
  display: flex;
  list-style: none;
  border-radius: 0;
  border-bottom: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.wam-summary-stat {
  border: 1px solid #929191;
  margin-top: 30px;
}

.wam-summary-stat .label {
  background: #d1dde5;
  font-weight: bold;
}

.wam-summary-stat .assigned span {
  color: red;
}

.wam-summary-stat .available span {
  color: green;
}

.wam-summary-stat>div {
  padding: 10px;
}

.no-title-card-alignment {
  margin-top: -40px;
}

.grid-header-top {
  position: absolute;
  left: 0;
  margin-left: 20px;
  z-index: 1;
  margin-top: 15px;
}

.p-0 {
  padding: 0;
}

.p-15 {
  padding: 15px;
}

.fr-popup.fr-active {
  z-index: 9999 !important;
}

.background-white {
  background: #fff;
}

.p-10 {
  padding: 10px;
}

.pull-right {
  float: right;
}

.disable-tr {
  color: #c4c1c1;
}

.dx-checkbox.dx-state-readonly.dx-state-hover .dx-checkbox-icon,
.dx-checkbox.dx-state-readonly.dx-state-focused .dx-checkbox-icon {
  border-color: rgba(49, 60, 135, 0.19);
  background-color: #fff;
}

.text-unwrap {
  text-overflow: unset;
  white-space: nowrap;
  overflow: visible;
}

.text-unwrap .dx-checkbox {
  float: left;
  margin-right: 4px;
}

.wam-licence-request-container .card-body .dx-collection>.dx-box-flex:first-child>.dx-box-item:first-child>.dx-item-content:first-child>.dx-box-item:first-child {
  flex: unset !important;
}

.title-hint {
  font-size: 14px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
}

.display-block {
  display: block;
}

.bubble-report-tooltip .tooltip-header {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.bubble-report-tooltip .tooltip-body {
  font-size: 15px;
}

.bubble-report-tooltip .tooltip-footer {
  margin-top: 25px;
}

.bubble-report-tooltip .btn {
  border-radius: 15px;
  padding: 3px 10px;
  color: #fff;
  background: #3d3b3b;
}

.bubble-report-tooltip .btn:hover {
  color: #fff;
  background: #000000;
}

.overflow-scroll .dx-scrollview-content,
.overflow-scroll .dx-scrollview-top-pocket {
  overflow: scroll;
}

.no-border {
  border: none;
}

.badge-clear-border {
  border: 1px solid;
}

.hide-first-td>td:first-child {
  display: none;
}

.transparent-bg-td,
.transparent-bg-td>td {
  color: #252d65 !important;
  background: transparent !important;
}

.activity-dimension-container .dx-datagrid .dx-row-alt>td,
.activity-dimension-container .dx-datagrid .dx-row-alt>tr>td {
  background-color: #f5f5f5 !important;
  border-top: 1px solid #f5f5f5 !important;
  border-bottom: 1px solid #f5f5f5 !important;
}

.activity-dimension-container .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):hover:not(.dx-row-lines)>td,
.activity-dimension-container .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-lines)>td {
  border: none;
}

.dimensionItem {
  width: 200px;
  height: 210px;
  margin: auto;
  box-shadow: 3px 3px 3px 8px lightblue;
}

.dimensionImage {
  width: 160px;
  height: 150px;
}

.dimensionContent {
  padding: 5px;
}

.row-button-group {
  min-height: 85px;
}

.button-absolute-right {
  position: absolute;
  right: 30px;
}

.row-button-group .button-absolute-right.cancel-btn {
  right: 145px;
  margin-top: 10px !important;
}

.question-preview .fr-view img {
  max-width: 200px;
  max-height: 200px;
}

.question-preview .card {
  border: none;
}

.shared-with-tooltip {
  width: 300px;
  word-wrap: break-word;
  white-space: pre-line;
}

.display-block {
  display: block !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.top-tab-bar {
  width: 400px;
  border: 1px solid;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 15px;
  background: #fff;
  text-align: center;
}

.top-tab-bar .col {
  padding: 8px;
  cursor: pointer;
}

.top-tab-bar .active {
  background: #2a4151;
  color: #fff;
}

a[activemenu="active"] {
  background: #ee4491 !important;
}

a[activemenu="active"],
a[activemenu="active"] i {
  color: #fff !important;
}

#cloze-answer-grid .dx-datagrid-headers {
  display: none;
}

#cloze-answer-grid .dx-datagrid-rowsview {
  border: none;
}

#cloze-answer-grid .dx-texteditor.dx-editor-outlined {
  border: 1px solid #626060;
}

.correctAnswerBox,
.incorrectAnswerBox {
  text-align: left !important;
}

.correctAnswerBox {
  border: 1px solid #b6e1b6 !important;
  background: #f1fef1 !important;
  color: #3b613b !important;
}

.incorrectAnswerBox {
  border: 1px solid #e9a5af !important;
  background: #fde9ec !important;
  color: #433030 !important;
}

.correctAnswerBox img,
.incorrectAnswerBox img {
  vertical-align: -2px !important;
  height: 25px !important;
  width: 20px !important;
}

.cloze-question-container input {
  line-height: 25px;
}

.cloze-question-container {
  line-height: 40px;
}

.cloze-question-container .dx-textbox {
  font-size: inherit !important;
}

.cloze-question-container .dx-selectbox {
  display: inline-block;
}

.cloze-question-container .fr-view {
  padding-left: 7px;
}

.cloze-input-0,
.cloze-input-half {
  width: 80px;
}

.cloze-input-1 {
  width: 180px;
}

.cloze-input-2 {
  width: 250px;
}

.cloze-input-3 {
  width: 85%;
}

select.cloze-input-0,
select.cloze-input-half,
select.cloze-input-1,
select.cloze-input-2,
select.cloze-input-3 {
  height: 32px;
}

.dx-selectbox.cloze-input-0,
.dx-selectbox.cloze-input-half,
.dx-selectbox.cloze-input-1,
.dx-selectbox.cloze-input-2,
.dx-selectbox.cloze-input-3 {
  height: 42px;
  line-height: 35px;
  margin-bottom: 15px;
}

.cloze-question-container .dx-dropdowneditor-field-template-wrapper .dx-texteditor-empty {
  display: none;
}

select.correctAnswerBox,
select.incorrectAnswerBox {
  opacity: 1;
}

.quizdetail-card.was-selected {
  border: 2px solid #aca9ab !important;
}

.quizdetail-card.was-selected .dx-checkbox-icon {
  color: #aca9ab !important;
}

.licenced-info-card {
  border: 1px solid #878282;
  text-align: center;
  font-weight: 600;
  margin: 20px;
  padding: 20px;
}

.force-hide {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .assessment-booklet-content {
    max-height: 120px;
    overflow: auto;
  }
}

.pl-15 {
  padding-left: 15px;
}

.quiz__assesment-name {
  color: #252d65;
  font-size: 22px;
  font-weight: bold;
  margin-top: 12px;
  display: inline-block;
}

.assessment-booklet-sp {
  border-right: 7px solid #ebedef;
  padding: 6px;
}

.assessment-question-no {
  border-top: 4px solid #ebedef;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-left: -15px;
}

.assessment-acCode {
  h5{ 
    font-size: 16px;
    font-weight: 500;
    color: #3c4b64;
    padding: 10px 0;

    span {
      color: #03bfd7;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-background:hover {
  background-color: #ffffdd;
}

// our own "expand" / "collapse" indicators to mimic the faulty ones provided by DevExtreme data grids in master-detail views.
// use in conjunction with onRowClick and onRowPrepared handlers provided by src/utils/DXMasterDetailHelperUtils.js
.bp-expanded:before,
.bp-collapsed:before,
.bp-expanded-accordion:before,
.bp-collapsed-accordion:before {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  color: map-get($map: $theme-colors, $key: "danger");
  width: 1em;
  float: left;
}

.bp-expanded-accordion:before,
.bp-collapsed-accordion:before {
  // bigger and in a circle to look like a button
  font-size: 1.5em;
  color: map-get($map: $theme-colors, $key: "danger");
  line-height: 1em;
  text-align: center;
}

.bp-expanded-accordion:hover::before,
.bp-collapsed-accordion:hover::before {
  // bigger and in a circle to look like a button
  background-color: map-get($map: $theme-colors, $key: "primary");
  border: 1px solid map-get($map: $theme-colors, $key: "primary");
  border-radius: 0.5em;
  color: white;
}

.bp-expanded-accordion:before,
.bp-expanded:before {
  content: "\f0d7"; // fontawesome down-caret
}

.bp-collapsed-accordion:before,
.bp-collapsed:before {
  content: "\f0da"; // fontawesome right-caret
}

// .bp-collapsed-accordion:before {
//   content: "\f0d8"; // fontawesome up-caret
// }

// a particular rule for the reviews data grid(s), to change the cursor to "pointer" to highlight to the user the rows are clickable
.reviews-data-grid>div.card>div.card-body>div.row>div.dx-widget>div>div.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic>div>div>div>div>table>tbody>tr {
  cursor: pointer;
}

// override the datagrid's pager since it's often off to the right of the screen (i.e. need a right-scroll to see it).
div.dx-datagrid-pager.dx-pager>div.dx-page-sizes {
  float: left;
}

div.dx-datagrid-pager.dx-pager>.dx-pages {
  float: left;
  margin-left: 5em;
}

// for List of test under Gap analysis

.list-gap-container {
  margin-bottom: 15px;
}

.topbar-warning {
  min-height: 30px;
  background: #eeb403;
  border: 1px solid #d9a507;
  color: #251d00;
}

.topbar-warning .c-header-nav {
  margin: 0 auto;
  min-height: unset;
}

// make the header responsive by truncating or hiding the school name and motto
header {
  >ul.c-header-nav {
    --lh: 1.2rem; // line height variable, used in calculation to clip multi-line text.
    line-height: var(--lh);

    // hide the school name, motto and "potential. unleashed." tagline on small screens, override to show on larger ones.
    .schoolNameAndLogoContainer,
    p.motto,
    .elastikTaglineImage {
      display: none;
    }

    // Small devices (landscape phones, 576px and up)
    @include media-breakpoint-up(sm) {
      .schoolNameAndLogoContainer {
        display: flex;
      }

      h6.schoolName {
        width: 12rem;
        line-height: var(--lh);
        // show an ellipsis if it's more than 2 lines worth
        // from https://css-tricks.com/multi-line-truncation-with-pure-css/
        --max-lines: 2;
        max-height: calc(var(--lh) * var(--max-lines));
        overflow: hidden;
        position: relative;

        &::before {
          content: "...";
          position: absolute;
          bottom: 0;
          right: 0;
        }

        &::after {
          content: "";
          position: absolute;
          right: 0;
          /* note: not using bottom */
          width: 1rem;
          height: 1rem;
          background: white;
        }
      }
    }

    // 992px (ipad landscape-ish) and up
    @include media-breakpoint-up(lg) {

      // show and truncate the motto to 14rem
      p.motto,
      h6.schoolName {
        max-width: 14rem;
      }

      p.motto {
        display: inline-block;
        max-height: 1.2rem;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    // end 1024px and larger media query

    // X-Large devices (large desktops, 1200px and up)
    @include media-breakpoint-up(xl) {

      p.motto,
      h6.schoolName {
        max-width: 20rem;
      }

      // show the "potential. unleashed." tag image.
      .elastikTaglineImage {
        display: initial;
      }
    }

    // XX-Large devices (larger desktops, 1400px and up)
    @include media-breakpoint-up(xxl) {

      p.motto,
      h6.schoolName {
        max-width: 28rem;
      }
    }
  }
}

// student grid save button and cancel
.classroom-grid {
  &__button-container {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    z-index: 2;
    width: 300px;
    margin-left: auto;
    top: -82px;
    left: -21px;
  }
}

.stick-header-datagrid .dx-datagrid .dx-datagrid-headers {
  position: sticky;
  background-color: #fff;
  z-index: 9;
  top: 65px;
}

.dx-multiview-wrapper:has(.stick-header-datagrid),
.dx-multiview-item:has(.stick-header-datagrid),
.card2:has(.stick-header-datagrid) {
  overflow: visible !important;
}

.merge-student-btn {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 15px;
}

.students-tab-top-buttons {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 15px;
}

.student-merge-container #gridContainer {
  min-height: 500px;
  margin-top: 30px;
}

.student-merge-container .title {
  margin-bottom: 15px;
}

.student-merge-container .student-filter-body {
  background: #f6f6f6;
  padding: 10px;
}

.hide {
  display: none;
}

.mb-10 {
  margin-bottom: 10px;
}

.student-merge-form input[type="radio"] {
  width: 10%;
  float: left;
}

.student-merge-form .dx-textbox,
.student-merge-form .dx-selectbox {
  width: 90%;
  float: left;
}

.widget-container {
  display: flex;
  justify-content: center;
}

.wam-file-uploader {
  margin: 0 auto;

  .file-failed-to-upload {
    color: #ee4491 !important;
  }

  .dx-fileuploader-input-wrapper {
    width: 350px;
    height: 200px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
    margin: 0 auto;
    border-color: #ddd;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .dx-button {
      order: 10;
    }
  }

  .dx-fileuploader-input-container {
    height: auto;
  }

  .dx-fileuploader-input-label {
    text-align: center;
    order: 1;
  }

  .dx-fileuploader-content {
    &>.dx-fileuploader-button {
      background-color: #0000ff;
      border: 1px solid #ddd;
      float: right;
      margin-top: 15px;
      margin-right: 17px;
      transition: background-color 400 ease;

      &::after {
        content: "";
        clear: both;
        display: table;
      }

      .dx-button-text {
        color: #fff;
      }

      &:hover {
        background-color: #000099 !important;
      }
    }
  }

  .dx-fileuploader-files-container {
    clear: both;

    .dx-fileuploader-file-container {
      margin: 10px 0;
    }
  }
}

.dx-fileuploader-dragover {
  .dx-fileuploader-input-wrapper {
    background-color: #fff;
  }

  .dx-fileuploader-input-container {
    border-color: #0000ff !important;
    border-style: solid !important;
  }

  .dx-fileuploader-input-container {
    height: 100%;
  }
}

.force-hide {
  display: none !important;
}

.introjs-skipbutton {
  font-size: 14px !important;
}

.tour-icon {
  cursor: pointer;
  margin-left: 15px;
}

.create-student__modal-container {
  .row {
    margin: 10px 0;

    .help-block {
      color: #ee4491 !important;
    }

    &.last-row {
      margin-top: 20px;

      .dx-button {
        margin-right: 10px;
      }
    }
  }
}

.create-student__modal-container_bc {
  .row {
    margin: 5px 0;

    .help-block {
      color: #ee4491 !important;
    }

    &.last-row {
      margin-top: 20px;

      .dx-button {
        margin-right: 10px;
      }
    }
  }
}

// Fuzzy modal
.fuzzy-modal__button-actions-container {
  margin-top: 17px;

  .error {
    font-size: 14px;
    color: red;
    margin-left: 20px;
  }

  .dx-button {
    margin-top: 5px;
    margin-right: 8px;
  }
}

.student-modal-create-classroom-student {
  .required {
    color: red;
    display: inline-block;
    margin-left: 5px;
  }
}

// Wam module
.card-progression-container {
  .general-progression-container {
    display: inline-block;
  }

  .general-progression-btn {
    padding: 5px;
  }

  .rubric-image {
    display: block;
    width: 155px;
    height: auto;
    margin: 20px 0;
  }

  .dx-button-content {
    font-size: 12px;
  }
}

.archive-tr {
  background: #d8d8d8;
}

.manage-test-popup,
.manage-alternate-name-popup {
  .back-btn {
    position: absolute;
    right: 118px;
    margin-top: -42px;
  }

  .assign {
    margin-top: -33px;
  }
}

// select box on login page to select country
.login-container .country-selectbox.country-selectbox {
  background: initial;
  border: 1px dotted #999;
  width: 14em;
  vertical-align: middle;
  display: flex;
  align-self: flex-end;
  position: absolute;
  top: 0;
}

.country-selectbox-label,
.country-selectbox-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flag-icon {
  max-width: 3em;
  padding: 0.5em;
}

.dx-button-has-text.button-text-link,
.dx-button-has-text.button-text-link:hover {
  border: 0;
  background: transparent;
  text-decoration: underline;
  text-underline-offset: 3px;
}

.dx-button-has-text.button-text-link .dx-button-content {
  padding: 5px;
}

.text-overflow-visible {
  overflow-x: visible !important;
  text-overflow: unset !important;
}

// intro.js ovveride css
.intro-tour-guide-container {
  &.introjs-tooltip {
    min-width: 288px;
  }

  .introjs-skipbutton {
    width: 121px;
  }
}

.icon-circle::before {
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem;
  margin: auto 0.1rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

// the "students" data grid - make edit links look like buttons
.students-grid .dx-datagrid .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table {

  // highlight the cells being edited
  .dx-row.dx-edit-row>td.dx-editor-cell {
    border: 1px solid map-get($map: $theme-colors, $key: "danger");
  }

  .dx-row td.dx-command-edit.dx-command-edit-with-icons {
    .dx-link.dx-link-icon {
      width: 35px;
      height: 35px;
      border-radius: 20px;
      border-color: $elastik-dx-icon-colour;
      border-style: solid;
      border-width: 1px;
      color: $elastik-dx-icon-colour;
      background-color: #fff;
      display: inline-block;
      margin: 0;

      &:hover {
        background-color: #f5f5f5;
      }

      &:before {
        padding: 8px;
        height: 16px;
        width: 16px;
        line-height: 35px;
      }
    }
  }
}

.icon-elastik-s4 {
  border-color: $elastik-dx-icon-colour;
  color: $elastik-dx-icon-colour;
}

.help-guide-file {
  opacity: 0.5;
  font-size: 21px;
  margin-left: 15px;
}

.student-grid-container {
  .dx-state-hover {
    .student-gap {
      cursor: pointer !important;
      background-color: #252d65 !important;
      color: #fff !important;
    }
  }
}

.help-guides {
  .dx-treelist-headers {
    display: none;
  }

  div.dx-treelist-rowsview  div.dx-treelist-icon-container > div.dx-treelist-empty-space::before {
    display:none;
  }

  .dx-treelist .dx-row > td.dx-treelist-cell-expandable {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  .dx-treelist-text-content > h4 {
    margin-bottom: 0;
  }

  .help-guide-item {
    padding: 8px 5px;
    font-size: 18px;
    font-weight: normal;
  }

  // ============================================================================================
  // ===== rules to animate the expand / collapse of the treelist ===============================
  .dx-treelist-rowsview {
    .dx-treelist-expanded,
    .dx-treelist-collapsed {
      span {
        font-size: 1.3125rem;
        // line-height: 1.2;
      }
    }

    tr.dx-row.dx-data-row {
      &.animated-expand {
        >td {
          .dx-treelist-expanded span::before {

            animation: quarter-spin-clockwise-reveal 150ms ease-in-out; // clockwise
          }
        }

        // ==== this set of selectors, when combined, is like a "multiple adjacent siblings" selector ====
        +tr:not([aria-level="1"]),
        // anything that's not level 1 can be collapsed; matches immediate lower-level siblings
        ~tr:not([aria-level="1"])+tr:not([aria-level="1"]) // immediate sibling of elements that match the selector on the line above this
          {
          td,
          td div {
            animation: elastik-reveal-height 150ms ease-in-out;
          }

          &[aria-expanded="false"] {
            td,
            td div {
              animation: elastik-reduce-height 150ms ease-in-out;
            }
          }
        }

        // negate/override matches of the previous selector if there's a [aria-level="1"] parent
        +tr[aria-level="1"]~tr:not([aria-level="1"]),
        ~tr[aria-level="1"]~tr:not([aria-level="1"])+tr:not([aria-level="1"]) // this one is needed to override the specificity level of the previous selector
          {
          >td,
          >td div {
            animation: initial;
          }

          &:not[aria-expanded="true"] {
            >td,
            >td div {
              animation: initial;
            }
          }
        }
      }

      &.animated-collapse {
        >td {

          .dx-treelist-expanded span::before {
            animation: quarter-spin-anticlockwise-reveal 150ms ease-in-out; // anticlockwise
          }
        }

        // ==== this set of selectors, when combined, is like a "multiple adjacent siblings" selector ====
        +tr:not([aria-level="1"]),
        // anything that's not level 1 can be collapsed; matches immediate lower-level siblings
        ~tr:not([aria-level="1"])+tr:not([aria-level="1"]) // immediate sibling of elements that match the selector on the line above this
          {
          >td,
          >td div {
            animation: elastik-reduce-height 150ms ease-in-out;
          }

        }

        // negate/override matches of the previous selector if there's a [aria-level="1"] parent
        +tr[aria-level="1"]~tr:not([aria-level="1"]),
        ~tr[aria-level="1"]~tr:not([aria-level="1"])+tr:not([aria-level="1"]) // this one is needed to override the specificity level of the previous selector
          {
          >td,
          >td div {
            animation: initial;
          }
        }
      }
    }
  }
  // ===== end treelist expand / collapse  ======================================================

}


.confirm-teacher-tranfer-message {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.test-summary-stats.table td {
  border: 1px solid #bebebe;
}

.test-summary-stats .icon-check {
  font-size: 20px;
  color: green;
}

// ovveride styles sidebar menu > submenu
.c-sidebar-nav-dropdown {
  .c-sidebar-nav-dropdown-items {
    background-color: #0707d9;

    @media (max-width: 900px) {
      background-color: #0707d9 !important;
    }

    li {
      .c-sidebar-nav-link {
        padding-left: 78px;
      }
    }
  }
}

.error-boundary {
  .outer-content-error {
    position: absolute;
    left: 50%;
    margin-left: -400px;
    width: 900px;

    img {
      position: absolute;
      right: 50%;
      margin-top: -50px;
    }
  }

  .code {
    background: #fcfcfc;
    padding: 5px;
    font-style: italic;
    color: #768192;
    clear: both
  }
}

.sticky-guide {
  box-shadow: -7px -8px 11px -3px rgba(163, 163, 163, 0.75);
  -webkit-box-shadow: -7px -8px 11px -3px rgba(163, 163, 163, 0.75);
  -moz-box-shadow: -7px -8px 11px -3px rgba(163, 163, 163, 0.75);
  position: "absolute";
  right: 0;
  height: "100%";
  z-index: 9;
  width: 500px;
}

.sticky-guide .close {
  color: white;
  border: 2px solid red;
  background: red;
  border-radius: 18px;
  width: 38px;
  padding: 7px;
  font-size: 21px;
  position: absolute;
  margin-top: 70px;
  right: 10px;
  cursor: pointer;
}

.help-guide-note {
  margin-top: -50px;
  font-style: italic;
  position: absolute;
}

/** preview question **/
.preview-questions-container {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
  padding: 20px;

  .question-card {
    border: 1px solid #add8e6;
    background: #fff;
    padding: 20px;
    transition: all .2s ease-out;
    
    &:hover {
      background-color: #fff;
      border: 1px solid #ccc;
      box-shadow: 0 4px 6px 8px rgba(38,38,38,.2);
      top: -4px;
    }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    flex:1;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .my-1 {
    .dx-checkbox {
      flex:0;
      
    }
    .ml-2 {
      flex: 3;
    }
  }
 
}
.message-container {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;

  .error-message {
    font-weight: 500;
    font-size: 30px;
    font-family: "Helvetica Neue", "Sergoe UI", sans-serif;
  }
}
