.help-guides {
  margin: 10px;
  .filterSection {
    padding: 20px;
    margin-left: -20px;
    width: 100%;
  }
  .helpGuideCards {
    width: 98%;
  }
  .header {
    background-color: #03bfd7 !important;
  }
  .title {
    width: 100%;
    display: flex;
    background-color: #03bfd7 !important;
    > h5 {
      font-size: 15pt;
    }
  }
  .description {
    padding: 20px;
  }
  .video {
    margin: auto;
    width: 60%;
    padding: 20px;

    @media screen and (max-width: 1544px) {
      width: 60%;
    }

    @media screen and (max-width: 1024px) {
      width: 80%;
    }
  }
  .iframe {
    width: 843px;
    height: 480px;

    @media screen and (max-width: 1544px) {
      width: 600px;
      height: 380px;
    }

    @media screen and (max-width: 1024px) {
      width: 500px;
      height: 380px;
    }
  }
}
