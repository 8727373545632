// styles for DropDown component - used as action button
// with icon in DataGrid row column
.row-actions {
  position: relative;
  .dx-buttongroup-item {
    border: none;
  }

  .dx-button-content {
    width: 25px;

    .dx-icon {
      position: absolute;
      bottom: 2px;
      left: 4px;
    }
  }
}
