.qrs-list {

    .listContainer {
        margin-top: 20px;
        float: none
    }

    .qrRow {
        margin: auto;
        width: 80%;
        margin-top: 50px !important;
    }

    .qrColumn {
        margin-left: 30px;

        .student-qr-card {

            width: 420px;
            height: 350px;

            .column {
                width: 90%;
                margin-top: 30px;
                margin-left: 30px;
            }

            .QR {
                margin-left: 15px;
                margin-right: auto;
            }

            .elastikLogo {
                margin-right: auto;
                margin-left: 15px;
            }

        }
    }

    .qrNoValidStudents {
        width: 95%;
        margin: auto
    }

    .page-break {
        @media screen {
            display: block;
        }

        @media print {
            margin-top: 1rem;
            display: block;
            page-break-after: always;
        }
    }
}