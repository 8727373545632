// container for the chat widget, used to position it in the bottom right corner on the login screen.
.chat-widget-container-pos-bottom {
  position: absolute; // parent container is set to position:relative with height:100%, so this is absolute with respect to the parent
  min-height: calc(
    100% - 4rem
  ); // this container is 100% the parent's height minus the height of the chat widget itself
  top: 0px; // top and bottom are 0 so that the container's height grows with the parent
  bottom: 0;
  right: 0;

  .chat-widget {
    // the chat widget itself
    margin: 0.5rem 0.5rem;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

// chat widget button colours
.chat-widget-container,
.chat-widget-container-pos-bottom {
  .chat-widget {
    .dx-button {
      background-color: map-get($theme-colors, elastik-blue);

      &:hover {
        background-color: lighten(
          $color: map-get($theme-colors, elastik-blue),
          $amount: 10
        );
      }

      &.dx-state-focused {
        background-color: map-get($theme-colors, elastik-s8);
      }
    }
  }
}

.chat-widget-container #chat-widget-button > div.dx-button-content {
  padding: 4px; // make the button smaller when it's in the top nav
}
