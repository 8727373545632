#login-page {
  .dx-popup-title {
    background-color: #fff;
  }
  .dx-popup-content {
    padding: 0;
  }
}

.welcome-modal-container {
  width: 650px;
  height: 375px;

  .container {
    padding: 25px;
  }

  .logo {
    text-align: left;
    box-sizing: border-box;
    img {
      width: 250px;
      height: auto;
      display: inline-block;

      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .content-description {
    box-sizing: border-box;
    background-color: #252d65;
    height: 233px;
    p {
      font-size: 14px;
      line-height: 28px;
      font-weight: normal;
      font-family: "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif;
      font-size: 15px;
      color: #fff;
    }
    a {
      margin-left: 4px;
      font-weight: 500;
      color: #fff;
      &:hover {
        text-decoration: underline;
        color: #fff;
      }
    }

    .elastik-logo {
      max-width: 230px;
      display: inline-block;
      width: 100%;
      height: auto;
    }
  }
}
