.wamPolarChart {

    @media (min-width: 576px) {
        width: 250px;
        height: 250px;
    }

    @media (min-width: 768px) {
        width: 500px;
        height: 500px;
    }

    @media (min-width: 992px) {
        width: 600px;
        height: 600px;
        margin-left: -100px;
    }

    @media (min-width: 1200px) {
        width: 800px;
        height: 600px;
        margin-left: -100px;
    }

}