.marks-book {
  &.card {
    margin-bottom: 0;
  }

  &__coordinator-assignments {
    height: calc(100vh - 170px);
  }

  .below-threshold {
    background-color: map-get($map: $theme-colors, $key: "elastik-s10"); // pink
  }

  .between-thresholds {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s15"
    ); // yellow
  }

  .above-threshold {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s17"
    ); // lightgreen
  }

  .marks-book-grid-card {
    .card2:hover {
      transition: none;
      box-shadow: none;
    }

    .marks-book-header {
      .card-title {
        margin: auto;
        margin-right: 10px;
      }

      .threshold-input {
        .row {
          div:first-child {
            margin-right: 10px;
          }
        }
      }
    }

    #marksbookGridContainer {
      // min-height: 500px;
      // min-width: 100%;

      .dx-datagrid .dx-row-alt > td,
      .dx-datagrid .dx-row-alt > tr > td {
        background-color: #eee;
      }

      .dx-datagrid-text-content {
        white-space: pre-wrap;
      }

      .dx-row.dx-header-row {
        height: 200px;
        overflow: hidden;
        text-overflow: ellipsis;

        /* lets just give the rotation to browsers that understand it otherwise they may also get transformed but not rotated. Now older browsers just get a normal table*/
        @supports (writing-mode: vertical-lr) {
          td {
            .dx-datagrid-text-content {
              vertical-align: middle;
              // transform:translate(25px, 51px) rotate(315deg);
              writing-mode: vertical-lr;
              transform: rotate(180deg);
              border: 1px solid #eee;
            }

            /* don't rotate the student name column header */
            &.marksbook-student-name .dx-datagrid-text-content {
              writing-mode: initial;
              vertical-align: bottom;
              padding-bottom: 1em;
              border: none;
              transform: none;
            }
          }
        }
      }

      td {
        padding: 0;
        vertical-align: middle;
      }

      td.marksbook-student-name {
        width: 200px;
        padding-left: 0.5em;
        .text-unwrap {
          min-width: 150px;
        }
      }

      .score-color-cell {
        padding: 6px 0;
        // max-width: 50px;
        margin: 0 auto;
        border-radius: 5px;
        font-weight: 500;
        color: black;
      }

      .custom-header {
        width: 50px;
        position: relative;
        margin: auto;
        padding: 4px;
        border-radius: 6px;
        height: 200px;

        div {
          &:first-child {
            margin-bottom: 10px;
          }

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          cursor: move;
          font-size: 11px;
        }

        &__threshold {
          span {
            display: block;
          }

          &-values {
            span {
              font-size: 8px;
            }
          }
        }
      }

      // table {
      //   tbody {
      //     td:not(:nth-child(1)),
      //     td:not(:nth-child(2)) {
      //       width: 120px;
      //     }
      //   }
      // }
    }
  }

  .action-buttons {
    padding: 20px;

    .dx-button-content {
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}

.marksbook-legend-colors {
  &.c1 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s6"
    ); // rgb(170,255,255),
  }

  &.c2 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s5"
    ); // rgb(204,204,204)
  }

  &.c3 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-blue"
    ); // blue
    color: white;
  }

  &.c4 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s17"
    ); // rgb(210,240,110),
  }

  &.c5 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s14"
    ); // rgb(255,255,110),
  }

  &.c6 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s13"
    ); // rgb(255,125,165),
  }

  &.c7 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s8"
    ); // rgb(0,220,255),
  }

  &.c8 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s4"
    ); // rgb(180,180,180),
  }

  &.c9 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s16"
    ); // rgb(190,230,35),
  }

  &.c10 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "primary-uk"
    ); // #252d65
  }

  &.c11 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s11"
    ); // rgb(250,215,205),
  }

  &.c12 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s7"
    ); // rgb(210,255,255),
  }

  &.c13 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s3"
    ); // rgb(242,242,242),
  }

  &.c14 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s15"
    ); // rgb(255,255,150),
  }

  &.c15 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s10"
    ); // rgb(190,230,35),
  }

  &.c16 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s9"
    ); // rgb(116,225,255),
  }

  &.c17 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s2"
    ); // rgb(230,230,230),
  }

  &.c18 {
    background-color: map-get(
      $map: $theme-colors,
      $key: "elastik-s14"
    ); // rgb(255,255,110),
  }
}

// Marskbook edit score
.marksbook-edit-score-button {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  z-index: 300;
  transform: translate(-30px, 183px);
  i {
    text-align: center;
    position: absolute;
    top: 0px;
    left: 4px;
    right: 50%;
    font-size: 16px;
    margin-right: 10px;
    transform: translate(34px, -4px) rotate(180deg);
    z-index: 4000;

    &.dx-icon-revert {
      color: map-get($map: $theme-colors, $key: "danger");
      transform: translate(31px, -2px) rotate(195deg);
    }

    &.dx-icon-edit {
      z-index: 4000;
    }
  }
}

.marksbook-score-textfield {
  input:disabled {
    background: #eee !important;
  }
}

.marksbook-edit-mode {
  input {
    border: 1px inset #ddd !important;
  }
}

.marksbook__btn_view_profile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 7px 18px 8px;
  background-color: #5fbf7f;
  border-color: transparent;
  color: #fff;
  transition: background-color 500ms ease-in-out;
  outline: none;
  border: none;

  &:hover {
    background-color: #27b958;
  }
}
