.ISTFilterForm {

   .cardsPadding{
       padding: 10px
   }

  .cardTitles{
      font-size: medium;
      padding: 10px
  }
}
