.quizdoc {
    .quizdoc-print-button {
        margin: 20px;
    }
    .quizdocQuestionsContainer {
        width: 80%;
        margin: auto;

        @media all {
            .page-break {
                display: none;
            }
        }
        @media print {
            .page-break {
                display: block;
                page-break-before: auto;
            }
        }
        .question-card {
            width: 95%;
            margin: auto;
        }
    }
}