.autocomplete-input {
  .optionList {
    border: 1px solid #5fbf7f;
    border-top-width: 0;
    list-style: none;
    margin-top: 0px;
    max-height: 143px;
    // overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    overflow: scroll;
  }
  .optionList li {
    padding: 8px;
  }
  .optionList > .active,
  .optionList li:hover {
    background-color: #5fbf7f;
    cursor: pointer;
    font-weight: 700;
    color: white
  }
  .optionList li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
}
