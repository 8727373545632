// Variable overrides
$grid-gutter-width: 0px;

$theme-colors: (
  "primary": #252d65,
  "info": #252d65,
  "secondary": #03bfd7,
  "danger": #ee4491,
  "success": #5fbf7f,
  "purple": #674ea7,
  "primary-uk": #252d65,
  "info-uk": #252d65,
  "secondary-uk": #aaffff,
  "danger-uk": #ffff96,
  "success-uk": #ffc8be,
  "purple-uk": #e6e6e6,
  "neutral": #cfcfcf,
  "lightOrange": #ffd480,
  "elastik-blue": #0000ff,
  "elastik-yellow": #ffff00,
  // elastik colour palette as per the brand book
  "elastik-s2": rgb(230,230,230),
  "elastik-s3": rgb(242,242,242),
  "elastik-s4": rgb(180,180,180),
  "elastik-s5": rgb(204,204,204),
  "elastik-s6": rgb(170,255,255),
  "elastik-s7": rgb(210,255,255),
  "elastik-s8": rgb(0,220,255),
  "elastik-s9": rgb(116,225,255),
  "elastik-s10": rgb(255,200,190),
  "elastik-s11": rgb(250,215,205),
  "elastik-s12": rgb(255,65,145),
  "elastik-s13": rgb(255,125,165),
  "elastik-s14": rgb(255,255,110),
  "elastik-s15": rgb(255,255,150),
  "elastik-s16": rgb(190,230,35),
  "elastik-s17": rgb(210,240,110),
);

$elastik-dx-icon-colour: rgb(114,128,138);

$font-family-sans-serif: "Helvetica Neue", "Segoe UI", Helvetica, Verdana,
  sans-serif;
