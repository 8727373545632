.progress-steps {
  margin: 30px 0;

  .dx-tabs {
    border: 0;
  }

  .dx-tabs-scrollable .dx-tabs-wrapper {
    border: 0;
  }
  .dx-tabs-wrapper {
    display: flex;
    justify-content: center;

    .dx-item {
      padding: 0px;
      background-color: transparent;

      &.dx-state {
        &-focused {
          border: 1px solid black;
        }
      }

      &.dx-tab {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        border-left: 1px solid black;

        &:last-child {
          border-right: 1px solid black;
        }
      }

      &-content {
        width: 36px;

        .normal-step {
          padding: 10px;
        }

        .disabled-step {
          height: 100%;
          background-color: lightgray;
          color: gray;
        }

        .selected-step {
          background-color: #5fbf7f;
          color: white;
        }
      }
    }
  }
}
