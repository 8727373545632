.react-quiz-container,
.react-quiz-container:hover {
  overflow: auto;
  margin: 10px;
  position: relative;
  // width: 60%;
  // top: 20px;
  // left: 0;
  // bottom: 0;
  // right: 0;
}

.react-quiz-container .quiz-header {
  position: relative;
  padding: 10px;
  color: #252d65 !important;
  margin-top: 20px;
}

.card-body .quiz-header {
  position: relative;
  padding: 10px;
  color: #252d65 !important;
  margin-top: 20px;
}

.react-quiz-container .quiz-subheader {
  position: relative;
  padding: 10px;
  color: #03bfd7;
}

.react-quiz-container .quizProgress {
  padding: 10px;
  color: #252d65 !important;
  font-weight: bold;
}
#progress-bar-status > div > div.dx-progressbar-range-container > div > div {
  background-color: #5fbf7f;
  border: #5fbf7f;
}

.react-quiz-container .quiz-synopsis {
  margin-left: 10px;
  color: #252d65;
}

.react-quiz-container .question-number {
  margin-top: 25px;
  background-color: #5fbf7f;
  border-radius: 20px;
  padding: 6px;
  text-align: center;
  color: white;
  width: 100px;
  font-size: 15px;
}

.react-quiz-container .result-answer .student-answer-container {
  display: table;
}

.react-quiz-container .questionWrapper {
  margin: 10px;
}

.react-quiz-container .question-detail {
  margin-top: 40px;
  padding-top: 10px;
  width: 60%;
}

.react-quiz-container .question-wrapper {
  display: initial;
  margin-top: 20px;
  position: relative;
  padding-top: 10px;
  width: 60%;

  input[type="text"] {
    padding: 5px;
    text-align: center;
  }
}

.test-answer-wrapper {
  input[type="text"] {
    padding: 5px;
    text-align: center;
  }
}

.react-quiz-container .quiz-questions {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 20px;
  padding: 10px;
  background-color: #03bfd7;
  color: white;
}

.react-quiz-container .startQuizWrapper .startQuizBtn {
  font-size: 15px;
  border-radius: 25px;
  line-height: 1.35135;
  color: white;
  background-color: #252d65;
  border: 1px solid #d9d9d9;
  padding: 10px;
  margin-top: 10px;
}

.react-quiz-container .startQuizWrapper {
  margin: 10px;
}

.react-quiz-container .btn {
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  padding: 11px 12px;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.react-quiz-container .questionWrapper .btn {
  margin-top: 5px;
  font-size: 15px;
  display: block;
  white-space: normal;
  text-align: unset;
  font-weight: normal;
}

.react-quiz-container .questionWrapper .nextQuestionBtn {
  color: white;
  background-color: #252d65;
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  font-size: 15px;
  line-height: 1.35135;
  margin-top: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
}

.react-quiz-container .questionWrapper .submitQuestionBtn {
  color: white;
  background-color: #5fbf7f;
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  font-size: 15px;
  line-height: 1.35135;
  margin-top: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
  margin-left: 5px;
}

.react-quiz-container .questionWrapper .previousQuestionBtn {
  color: white;
  background-color: #252d65;
  border: 1px solid #d9d9d9;
  border-radius: 25px;
  font-size: 15px;
  line-height: 1.35135;
  margin-top: 10px;
  padding: 10px;
  width: 100px;
  text-align: center;
}

.react-quiz-container .questionWrapper .btn.correct {
  background: #5fbf7f !important;
  color: white;
}

.react-quiz-container .questionWrapper .selected {
  border: 1px solid #03bfd7;
}

.react-quiz-container .questionWrapper .question-pic {
  --width: 222px;
  --height: 508px;
  margin-bottom: 10px;
}

.react-quiz-container .questionWrapper .question-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.react-quiz-container .questionWrapper .question-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.react-quiz-container .result-answer .question-row .btn {
  width: 100%;
  border: none;
}

.react-quiz-container .result-answer .answerCorrectDefinition {
  color: #5fbf7f !important;
  opacity: 0.8;
  padding: 10px;
  margin: auto;
  font-size: 18px;
  display: table-cell;
  vertical-align: middle;
}

.react-quiz-container .result-answer .answerIncorrectDefinition {
  color: #fc2448 !important;
  opacity: 0.8;
  padding: 10px;
  margin: auto;
  font-size: 18px;
  display: table-cell;
  vertical-align: middle;
}

.react-quiz-container .questionModal .alert {
  margin: 0px 0px 20px 0px;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
}

.react-quiz-container .questionModal .alert .explanation {
  font-style: italic;
}

.react-quiz-container .correct {
  background: #5fbf7f !important;
  color: black;
  opacity: 0.8 !important;
  border-radius: 0.25rem !important;
  div > div.dx-texteditor-input-container > input {
    font-weight: bold;
    color: black;
  }
}

.react-quiz-container .incorrect {
  background: #fc2448 !important;
  color: white;
  opacity: 0.8 !important;
  border-radius: 0.25rem !important;
  div > div.dx-texteditor-input-container > input {
    font-weight: bold;
    color: white;
  }
}

.react-quiz-container .questionWrapper img {
  // width: 100%;
}

// .react-quiz-container .result-answer-wrapper img {
//   width: 25%;
//   padding: 10px;
// }

// .react-quiz-container .result-answer-wrapper .btn img {
//   width: 100%;
//   padding: 10px;
// }

.react-quiz-container .answerBtnImage {
  // width: 120px;
  // height: 130px;
  border: none;
  background: none;
  box-shadow: none;
}

.react-quiz-container .answerBtnImage:focus,
.react-quiz-container .answerBtnImage:active,
.react-quiz-container .answerBtnImage:hover {
  // width: 120px;
  // height: 130px;
  background: none;
  border: none;
  box-shadow: none;
}

.react-quiz-container .answerBtnImageActive {
  // width: 120px;
  // height: 130px;
  background: none;
  border: 0.2em solid gray; /* here configure as your needs */
  color: gray;
  border-radius: 25px;
}

.react-quiz-container .answerBtnText {
  width: 25%;
  border: 0.2em solid transparent;
  background: none;
  box-shadow: none;
  border-radius: 7px;
  border: 0.2em solid lightgrey;
}

.react-quiz-container .answerBtnText:focus,
.react-quiz-container .answerBtnText:active,
.react-quiz-container .answerBtnText:hover {
  width: 25%;
  background: none;
  border: 0.2em solid lightgrey;
  box-shadow: none;
  border-radius: 7px;
}

.react-quiz-container .answerBtnTextActive {
  width: 25%;
  background: #ffff91;
  border: 0.2em solid #585757; /* here configure as your needs */
  color: #565555;
  border-radius: 7px;
}

.react-quiz-container .booklet-container .answerBtnText,
.react-quiz-container .booklet-container .answerBtnText:hover,
.react-quiz-container .booklet-container .answerBtnTextActive {
  width: 100% !important;
}
.booklet-container .title {
  margin-top: -25px;
}

.react-quiz-container .answerButtonContainer {
  margin-top: 20px;
}

.react-quiz-container .answerButtonTextContainer {
  margin-top: 20px;
}

.rte-container span {
  white-space: break-spaces;
}

.react-quiz-container .amplifyImage {
  margin: auto;
  // --height: 90px;
  // --width: 100px;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-radius: 5px;
  padding: 20px;
}

.react-quiz-container .result-answer-wrapper h3 {
  color: #252d65;
  margin: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.react-quiz-container .result-answer-wrapper .explanation {
  padding: 10px;
  margin: 10px 20px 20px 10px;
  border: 1px solid #e8e8e8;
  font-style: italic;
  background-color: #5fbf7f;
  opacity: 0.75;
  color: whitesmoke;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin-top: 20px;
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

.react-quiz-container .quiz-result-filter {
  width: 150px;
  margin-bottom: 10px;
}

.react-quiz-container .tag-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: stretch;
  align-content: stretch;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection {
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #03bfd7;
  margin-left: 5px;
}

.incorrect-answer {
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
  background: #fc2448;
  margin-left: 5px;
}

.correct-answer {
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
  background: #5fbf7f;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #252d65;
}

.react-quiz-container .selection-tag.multiple {
  background: #ee4491;
}

.react-quiz-container .buttons-container {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
}

.react-quiz-container .answerBtnTextActive.wd-50,
.react-quiz-container .answerBtnText.wd-50 {
  width: 50%;
}

// Quiz card
.quiz-container {
  &__card {
    height: 200px;
    box-sizing: border-box;
    padding: 20px;
  }
  &__error-message {
    padding: 5px;
    font-size: 24px;
    font-weight: 500;
  }
}
