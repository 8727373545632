.wamIndividualReport {
  padding: 20px;
  .wamWidget {
    padding: 10px;
    color: #252d65 !important;
    font-size: 14pt !important;
    font-weight: 600 !important;
    background-color: #e6e6e6 !important;
    .score {
      margin-top: 20px !important;
      font-size: 16pt !important;
      font-weight: 800 !important;
    }
    .highlighedPercentage {
      margin-left: 20px;
      background-color: #03bfd7;
      color: black;
      font-weight: 600;
    }
    .unHighlighedPercentage {
      margin-left: 20px !important;
    }
    > div {
      > hr {
        background-color: #252d65;
      }

      > div {
        display: flex;
        > h6 {
          width: 120px;
        }
      }
    }
  }
}
